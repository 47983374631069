import useSWR from 'swr';

import fetcher from 'utilities/fetcher';

export default function useSearch(term, options) {
  const { data, error } = useSWR(
    `/products/search?term=${term}`,
    fetcher,
    options
  );
  const loading = !data && !error;

  return {
    loading,
    error,
    products: data ? data : [],
  };
}
