import * as React from 'react';

function Dots(props) {
  return (
    <svg x="0px" y="0px" viewBox="0 0 100 100" xmlSpace="preserve" {...props}>
      <circle fill={props.fill || '#233142'} cx={6} cy={50} r={6}>
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin={0.1}
        />
      </circle>
      <circle fill={props.fill || '#233142'} cx={26} cy={50} r={6}>
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin={0.2}
        />
      </circle>
      <circle fill={props.fill || '#233142'} cx={46} cy={50} r={6}>
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin={0.3}
        />
      </circle>
    </svg>
  );
}

export default Dots;
